import { Button } from "@/Components/Common/Button";
import SiteLayout from "@/Layouts/SiteLayout";
import { BidBadgeWithCountdown } from "@/Components/Common/BidBadgeWithCountdown";
import moment from "moment-timezone";
import { useEffect, useState, useRef } from "react";
import { Link } from "@inertiajs/react";
import DropdownFilter from "@/Components/Common/DropdownFilter";
import useFirearm from "@/Hooks/useFirearm";
import { ProgressSpinner } from "primereact/progressspinner";
import route from "ziggy-js";
import { FilterSearchBar } from "@/Components/FilterSearchBar";
import { Seo } from "@/Components/Seo";
import SoldItemGrid from "@/Components/Common/SoldItemGrid";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { OverlayPanel } from "primereact/overlaypanel";
import ReactModal from "react-modal";
import { Banner as HomeBanner } from "@/Components/Home/Banner";
import axios from "axios";

ReactModal.setAppElement("#root");
export default function LiveAuction({ isEmpty, endingItems, q }) {
  endingItems.original.data = endingItems.original.data.filter((item) => {
    const itemId = item.gunbroker_item_id;
    return itemId !== "115000331433" && itemId !== "75";
  });
  const matchingIds = [];

  endingItems.original.data.forEach((item) => {
    const itemId = item.gunbroker_item_id;

    if (itemId === "115000331433" || itemId === "75") {
      matchingIds.push(itemId);
    }
  });

  const {
    items,
    setItems,
    itemsOffset,
    setItemsOffset,
    getFirearms,
    isLoading,
    selectedManufacturer,
    setSelectedManufacturer,
    selectedCaliber,
    setSelectedCaliber,
    selectedCategory,
    setSelectedCategory,
    selectedPlatform,
    setSelectedPlatform,
    sortBy,
    setSortBy,
    query,
    setQuery,
    viewAllBtnIsVisible,
    setViewAllBtnIsVisible,
    isInfiniteScroll,
    setIsInfiniteScroll,
    manufacturersOptions,
    setManufacturersOptions,
    caliberOptions,
    setCaliberOptions,
    platformOptions,
    setPlatformOptions,
    categoryOptions,
    setCategoryOptions,
    itemsSoldRecently,
    recentlySoldTotalCount,
    setIsSearchPage,
    itemsHasReachedEnd,
    setItemsHasReachedEnd,
  } = useFirearm(endingItems.original.data);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setInterval(() => {
      if (document.body.textContent.includes("Last Call")) {
        updateLastCallData();
      }
    }, 60000);
  }, []);

  const updateLastCallData = async () => {
    let lastCallGunbrokerIds = JSON.parse(
      localStorage.getItem("lastCallGunbrokerIds") || "[]"
    );

    lastCallGunbrokerIds.forEach(async (id) => {
      try {
        const response = await axios.get(`/api/getItem/${id}`);
        updateItemByGunbrokerItemId(
          response.data.data.gunbroker_item_id,
          response.data.data
        );
        lastCallGunbrokerIds = lastCallGunbrokerIds.filter(
          (item) => item !== id
        );
        localStorage.setItem(
          "lastCallGunbrokerIds",
          JSON.stringify(lastCallGunbrokerIds)
        );
      } catch (error) {
        console.error("Error fetching data for ID:", id, error);
      }
    });
  };

  const updateItemByGunbrokerItemId = (gunbrokerItemId, newData) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.gunbroker_item_id === gunbrokerItemId
          ? { ...item, ...newData }
          : item
      )
    );
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("lastCallGunbrokerIds");
    };
  }, []);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalIsOpen]);

  useEffect(() => {
    const bannerShown = sessionStorage.getItem("hideHomeBanner");
    if (!bannerShown) {
      const delay = 2000; // 3 seconds

      setTimeout(() => {
        setModalIsOpen(true);
      }, delay);
    }
  }, []);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    sessionStorage.setItem("hideHomeBanner", true);
  };

  useEffect(() => {
    if (isEmpty) {
      setSortBy("recently_sold");
    }

    setIsSearchPage(true);

    if (q.q) {
      setQuery(q.q);
    }

    if (q.manufacturer) {
      setSelectedManufacturer({
        code: q.manufacturer,
        name: q.manufacturer,
      });
    }

    if (q.caliber) {
      setSelectedCaliber({
        code: q.caliber,
        name: q.caliber,
      });
    }

    if (q.category) {
      setSelectedCategory({
        code: q.category,
        name: q.category,
      });
    }

    if (q.platform) {
      setSelectedPlatform({
        code: q.platform,
        name: q.platform,
      });
    }

    // router.get('/live-auction', { search: 'John' }, { replace: true })
    setManufacturersOptions(
      Object.values(endingItems.original.filters.manufacturers).map(
        (manufacturer) => {
          return {
            name: manufacturer,
            code: manufacturer,
          };
        }
      )
    );

    setCaliberOptions(
      Object.values(endingItems.original.filters.calibers).map((caliber) => {
        return {
          name: caliber,
          code: caliber,
        };
      })
    );

    setPlatformOptions(
      Object.values(endingItems.original.filters.platforms).map((platform) => {
        return {
          name: platform,
          code: platform,
        };
      })
    );

    setCategoryOptions(
      Object.values(endingItems.original.filters.categories).map((category) => {
        return {
          name: category,
          code: category,
        };
      })
    );
  }, []);

  const fetchFirearms = async (options = {}) => {
    if (isLoading) return;

    const params = {
      sort_by: sortBy === "ending" ? "ending" : "new",
      order_by: sortBy === "ending" ? "asc" : "desc",
      is_ending: true,
      offset: itemsOffset,
      manufacturer: selectedManufacturer ? selectedManufacturer.code : null,
      caliber: selectedCaliber ? selectedCaliber.code : null,
      platform: selectedPlatform ? selectedPlatform.code : null,
      category: selectedCategory ? selectedCategory.code : null,
      ...options,
    };

    const response = await getFirearms(params);

    if (response.data.length < 16) {
      setItemsHasReachedEnd(true);
    }

    return response;
  };

  const fetchSoldFirearms = async (options = {}) => {
    const params = {
      sort_by: "recently_sold",
      order_by: "desc",
      is_sold: true,
      offset: itemsOffset,
      manufacturer: selectedManufacturer ? selectedManufacturer.code : null,
      caliber: selectedCaliber ? selectedCaliber.code : null,
      platform: selectedPlatform ? selectedPlatform.code : null,
      category: selectedCategory ? selectedCategory.code : null,
      ...options,
    };

    const response = await getFirearms(params);

    if (response.data.length < 16) {
      setItemsHasReachedEnd(true);
    }

    return response;
  };

  const handleChangeQuery = async (e) => {
    setQuery(e.target.value);
  };

  const handleResetSearch = async () => {
    setQuery("");
  };

  const handleViewAllAuctions = async () => {
    // history.pushState({}, '', '/live-auction?test=test')
    // router.visit()
    setItemsOffset(itemsOffset + 16);
    setImageLoaded(false);
    const params = {
      sort_by: sortBy === "ending" ? "ending" : "new",
      order_by: sortBy === "ending" ? "asc" : "desc",
    };

    const firearms = await fetchFirearms(params);

    if (firearms.data.length > 0) setItems([...items, ...firearms.data]);
    setViewAllBtnIsVisible(false);
    setIsInfiniteScroll(true);
  };

  const handleViewSoldAuctions = async () => {
    setItemsOffset(itemsOffset + 16);

    const firearms = await fetchSoldFirearms();

    if (firearms.data.length > 0) setItems([...items, ...firearms.data]);
    setViewAllBtnIsVisible(false);
    setIsInfiniteScroll(true);
  };

  const handleScroll = async () => {
    if (isLoading || itemsHasReachedEnd) return;
    if (
      document.documentElement.offsetHeight -
        500 -
        document.documentElement.scrollTop >
      800
    )
      return;
    // if (
    //     window.innerHeight + document.documentElement.scrollTop !==
    //     document.documentElement.offsetHeight
    // ) {
    //     return;
    // }

    const params = {
      sort_by: sortBy === "ending" ? "ending" : "new",
      order_by: sortBy === "ending" ? "asc" : "desc",
    };

    let firearms = [];

    if (isEmpty) {
      firearms = await fetchSoldFirearms(params);
    } else {
      firearms = await fetchFirearms(params);
    }

    if (firearms.data.length > 0) setItems([...items, ...firearms.data]);

    if (firearms.data.length < 16) {
      setItemsHasReachedEnd(true);
    }
    setImageLoaded(false);
    setItemsOffset(itemsOffset + 16);
  };

  useEffect(() => {
    if (!isInfiniteScroll) return;

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [
    isInfiniteScroll,
    document.documentElement.offsetHeight,
    document.documentElement.scrollTop,
  ]);

  const handleEndingSoon = async (e) => {
    e.preventDefault();
    setSortBy("ending");
    setItemsOffset(16);

    const firearms = await fetchFirearms({
      offset: 0,
      sort_by: "ending",
      order_by: "asc",
    });

    setItems(firearms.data);
  };

  const handleNewlyAdded = async (e) => {
    e.preventDefault();
    setSortBy("new");
    setItemsOffset(16);

    const firearms = await fetchFirearms({
      offset: 0,
      sort_by: "new",
      order_by: "desc",
    });

    setItems(firearms.data);

    setViewAllBtnIsVisible(true);
    setIsInfiniteScroll(false);
  };

  const handleManufacturerChange = async (e) => {
    setSelectedManufacturer(e.value);
  };

  const handleCaliberChange = async (e) => {
    setSelectedCaliber(e.value);
  };

  const handlePlatformChange = async (e) => {
    setSelectedPlatform(e.value);
  };

  const handleCategoryChange = async (e) => {
    setSelectedCategory(e.value);
  };

  function withPrefix(url, prefix) {
    const urlParts = url.split("/");
    const fileNameIndex = urlParts.length - 1;
    urlParts[fileNameIndex] = `${prefix}${urlParts[fileNameIndex]}`;
    return urlParts.join("/");
  }

  const handleImageError = (e) => {
    e.target.src = withPrefix(e.target.src, "1-");
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [filterVisible, setFilterVisible] = useState(false);
  const [cardsGrid, setCardsGrid] = useState(1);
  const opSearch = useRef(null);

  function resetFilter() {
    setSelectedManufacturer(null);
    setSelectedCaliber(null);
    setSelectedPlatform(null);
    setSelectedCategory(null);
    setSortBy("ending");
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "95%", // Full width
      border: "none",
      padding: 0, // Adjust padding as needed
      overflow: "hidden", // Hide overflow
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000, // Increase this value as needed
    },
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "20px",
    background: "transparent",
    border: "none",
    color: "maroon",
    fontSize: "32px", // Adjust the size as needed
    cursor: "pointer",
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const placeholderImages = [
    "/images/static/gun-skele1.png",
    "/images/static/gun-skele2.png",
    "/images/static/gun-skele3.png",
  ];

  const getRandomPlaceholderImage = () => {
    const randomIndex = Math.floor(Math.random() * placeholderImages.length);
    return placeholderImages[randomIndex];
  };

  return (
    <div>
      <div>
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Home Banner"
          style={customStyles} // Apply the custom styles
        >
          <button onClick={handleCloseModal} style={closeButtonStyle}>
            &times;
          </button>
          <HomeBanner />
        </ReactModal>
      </div>
      <Seo
        pageTitle="Used Guns for Sale | Used Gun Auctions | FirearmLand"
        title="Used Gun Auctions Every Week | FirearmLand"
        description="Bid on thousands of used guns in no-reserve penny gun auctions at FirearmLand. Discover rare, collectible, and everyday firearms. New inventory added weekly."
      />

      <SiteLayout>
        <div className="container mx-auto my-8 px-5 2xl:px-0">
          <div className={`sticky top-0 bg-white z-50 ${isSticky ? "" : ""}`}>
            <div className="hidden lg:block my-8 py-6">
              {isEmpty && <div>No items found.</div>}
              <div className="flex justify-between">
                {isEmpty ? (
                  <h1 className="text-[32px] font-semibold">
                    Showing Recently Sold
                  </h1>
                ) : (
                  <h1 className="text-[32px] font-semibold">Live Auctions</h1>
                )}
                <hr style={{ border: "1px solid black", margin: "20px 0" }} />
                {!isEmpty && (
                  <div className="flex gap-x-[22px] items-center">
                    <a
                      href="#"
                      className={`pb-1 ${
                        sortBy === "ending" &&
                        "border-b-[2px] border-black font-semibold"
                      }`}
                      onClick={handleEndingSoon}
                    >
                      Ending Soon
                    </a>
                    <a
                      href="#"
                      className={`pb-2 ${
                        sortBy === "new" &&
                        "border-b-[2px] border-black font-semibold"
                      }`}
                      onClick={handleNewlyAdded}
                    >
                      Newly Added
                    </a>
                  </div>
                )}
              </div>

              <div className="flex justify-between mt-[10px]">
                <div className="flex gap-x-[22px]">
                  <DropdownFilter
                    value={selectedManufacturer}
                    optionLabel="name"
                    options={manufacturersOptions}
                    onChange={handleManufacturerChange}
                    placeholder="Manufacturer"
                  />
                  <DropdownFilter
                    value={selectedCategory}
                    optionLabel="name"
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    placeholder="Categories"
                  />
                  <DropdownFilter
                    value={selectedPlatform}
                    optionLabel="name"
                    options={platformOptions}
                    onChange={handlePlatformChange}
                    placeholder="Platform"
                  />
                  <DropdownFilter
                    value={selectedCaliber}
                    optionLabel="name"
                    options={caliberOptions}
                    onChange={handleCaliberChange}
                    placeholder="Caliber"
                  />
                </div>

                <div className="flex gap-x-[22px] items-center ml-4">
                  <FilterSearchBar
                    query={query}
                    handleChangeQuery={handleChangeQuery}
                    handleResetSearch={handleResetSearch}
                  />
                </div>
              </div>
            </div>

            <div className="block lg:hidden flex justify-between mb-3">
              {isEmpty ? (
                <h1 className="text-[24px] font-semibold mt-1">
                  Showing Recently Sold
                </h1>
              ) : (
                <h1 className="text-[24px] font-semibold mt-1">
                  Live Auctions
                </h1>
              )}

              <div className="flex justify-between">
                <button
                  onClick={(e) => opSearch.current.toggle(e)}
                  type="button"
                  label="Show"
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 21.5L16.7 17.2"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                {cardsGrid == 1 && (
                  <button
                    onClick={() => setCardsGrid(2)}
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 3.5H4C3.44772 3.5 3 3.94772 3 4.5V9.5C3 10.0523 3.44772 10.5 4 10.5H9C9.55228 10.5 10 10.0523 10 9.5V4.5C10 3.94772 9.55228 3.5 9 3.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 3.5H15C14.4477 3.5 14 3.94772 14 4.5V9.5C14 10.0523 14.4477 10.5 15 10.5H20C20.5523 10.5 21 10.0523 21 9.5V4.5C21 3.94772 20.5523 3.5 20 3.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 14.5H15C14.4477 14.5 14 14.9477 14 15.5V20.5C14 21.0523 14.4477 21.5 15 21.5H20C20.5523 21.5 21 21.0523 21 20.5V15.5C21 14.9477 20.5523 14.5 20 14.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 14.5H4C3.44772 14.5 3 14.9477 3 15.5V20.5C3 21.0523 3.44772 21.5 4 21.5H9C9.55228 21.5 10 21.0523 10 20.5V15.5C10 14.9477 9.55228 14.5 9 14.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}

                {cardsGrid == 2 && (
                  <button
                    onClick={() => setCardsGrid(1)}
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 3.5H4C3.44772 3.5 3 3.94772 3 4.5V9.5C3 10.0523 3.44772 10.5 4 10.5H20C20.5523 10.5 21 10.0523 21 9.5V4.5C21 3.94772 20.5523 3.5 20 3.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 14.5H4C3.44772 14.5 3 14.9477 3 15.5V20.5C3 21.0523 3.44772 21.5 4 21.5H20C20.5523 21.5 21 21.0523 21 20.5V15.5C21 14.9477 20.5523 14.5 20 14.5Z"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}

                <button
                  onClick={() => setFilterVisible(true)}
                  type="button"
                  label="Show"
                  className="inline-flex items-center pl-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 6.5H21"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 12.5H17"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 18.5H14"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <Dialog
              header="Filter Items"
              visible={filterVisible}
              onHide={() => setFilterVisible(false)}
              className="w-screen"
            >
              <div className="">
                <div className="font-semibold py-2">Manufacurer</div>
                <DropdownFilter
                  value={selectedManufacturer}
                  optionLabel="name"
                  options={manufacturersOptions}
                  onChange={handleManufacturerChange}
                  placeholder="Select"
                />

                <div className="font-semibold py-2">Categories</div>
                <DropdownFilter
                  value={selectedCategory}
                  optionLabel="name"
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                  placeholder="Select"
                />

                <div className="font-semibold py-2">Platform</div>
                <DropdownFilter
                  value={selectedPlatform}
                  optionLabel="name"
                  options={platformOptions}
                  onChange={handlePlatformChange}
                  placeholder="Select"
                />

                <div className="font-semibold py-2">Caliber</div>
                <DropdownFilter
                  value={selectedCaliber}
                  optionLabel="name"
                  options={caliberOptions}
                  onChange={handleCaliberChange}
                  placeholder="Select"
                />

                {!isEmpty && (
                  <div className="mt-6">
                    <div className="font-semibold py-4">Sort By</div>
                    <div className="flex flex-wrap gap-10">
                      <div className="flex gap-x-[22px] items-center">
                        <a
                          href="#"
                          className={`pb-1 ${
                            sortBy === "ending" &&
                            "border-b-[2px] border-black font-semibold"
                          }`}
                          onClick={handleEndingSoon}
                        >
                          Ending Soon
                        </a>
                        <a
                          href="#"
                          className={`pb-2 ${
                            sortBy === "new" &&
                            "border-b-[2px] border-black font-semibold"
                          }`}
                          onClick={handleNewlyAdded}
                        >
                          Newly Added
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="w-full h-px mx-auto mt-12 mb-4 bg-stone-300 border-0 rounded md:my-10"></hr>

                <a
                  href="#"
                  onClick={resetFilter}
                  className="float-left mt-2 font-bold"
                >
                  Reset
                </a>

                <a
                  href="#"
                  className="float-right px-[20px] py-[10px] text-neutral-50 rounded-lg transition-colors bg-maroon hover:bg-maroon-light items-center justify-center"
                  onClick={() => setFilterVisible(false)}
                >
                  Done
                </a>
              </div>
            </Dialog>
            <OverlayPanel ref={opSearch} closeOnEscape className="w-screen">
              <FilterSearchBar
                query={query}
                handleChangeQuery={handleChangeQuery}
                handleResetSearch={handleResetSearch}
              />
            </OverlayPanel>
          </div>
          {isEmpty ? (
            <>
              <SoldItemGrid items={items} isEmpty={isEmpty} />
            </>
          ) : (
            <div
              className={
                "grid grid-flow-row grid-cols-" +
                cardsGrid +
                " lg:grid-cols-4 gap-4 sm:gap-8"
              }
            >
              {items.map((item, index) => {
                const targetTime = moment.tz(
                  item.ending_time,
                  "America/New_York"
                );
                const images = item.photo_array.split(",");

                const photo1 = [
                  item.year_manufactured,
                  item.make_verified,
                  item.model_verified,
                  item.submodel_verified,
                  item.caliber_verified,
                  item.finish_color,
                  "in",
                  item.overall_condition,
                  "Condition Thumbnail",
                ]
                  .join(" ")
                  .trim()
                  .split(/ +/)
                  .join(" ");

                // 🔴 SECURITY HOLE: passing untrusted input to dangerouslySetInnerHTML
                const markup = { __html: item.subtitle_for_gunbroker };

                return (
                  <div
                    className="shadow-sm rounded-[4px] pb-5 overflow-hidden"
                    key={index}
                  >
                    <Link
                      href={route("item", {
                        gunbrokerId: item.gunbroker_item_id,
                      })}
                      className="block"
                    >
                      <div
                        // className="h-[200px] mb-4 flex items-end bg-cover-imp"
                        // style={{
                        //     background: `url('${images[0].replaceAll(
                        //         '"',
                        //         ""
                        //     )}') no-repeat center`,
                        // }}
                        className="relative"
                      >
                        <img
                          src={
                            imageLoaded
                              ? images[0]?.replaceAll('"', "")
                              : getRandomPlaceholderImage()
                          }
                          className="relative z-0"
                          loading="lazy"
                          onError={handleImageError}
                          alt={photo1}
                          onLoad={handleImageLoad}
                        />

                        <BidBadgeWithCountdown
                          className="rounded-tl-none rounded-bl-none rounded-br-none absolute bottom-0 z-10"
                          targetTime={targetTime}
                          bid={item.current_bid}
                          gunbrokerId={item.gunbroker_item_id}
                          cards={cardsGrid}
                        />
                      </div>
                    </Link>

                    <Link
                      href={route("item", {
                        gunbrokerId: item.gunbroker_item_id,
                      })}
                      className="block"
                    >
                      <h2
                        className="text-lg font-bold tracking-[0.09px] mb-1 h-[55px] ellipsis-2"
                        alt="Glock Factory Original Glock 22 G22 Magazine . Glock Factory Original Glock 22 G22 Magazine ."
                      >
                        {item.title_for_gunbroker}
                      </h2>
                    </Link>

                    <Link
                      href={route("item", {
                        gunbrokerId: item.gunbroker_item_id,
                      })}
                      className="block"
                    >
                      <p
                        className="text-[15px] ellipsis-3"
                        dangerouslySetInnerHTML={markup}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          {items.length === 0 && !isLoading && (
            <div className="text-left mt-8">No Results Found</div>
          )}

          {isLoading && (
            <div className="flex justify-center items-center h-[150px] w-full">
              <ProgressSpinner
                style={{ width: "75px", height: "75px" }}
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
          )}

          {items.length === 16 && (
            <div
              className={`text-center ${viewAllBtnIsVisible ? "" : "hidden"}`}
            >
              {isEmpty ? (
                <Button
                  className=" h-[35px] my-8"
                  onClick={handleViewSoldAuctions}
                >
                  View All Sold Auctions
                </Button>
              ) : (
                <Button
                  className=" h-[35px] my-8"
                  onClick={handleViewAllAuctions}
                >
                  View All Auctions
                </Button>
              )}
            </div>
          )}
        </div>
      </SiteLayout>
    </div>
  );
}
